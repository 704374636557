<div class="modal-content">
    <div class="modal-body bg-primary text-white border border-primary border-bottom-0">
        <h4 class="modal-title text-white">
            Session Timeout
        </h4>
        <div>
            <small>
                {{ description }}
            </small>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary"
                type="button"
                (click)="handleContinueBtnClicked()"
        >
            Continue <span *ngIf="!loggedOut"><span *ngIf="displayedTime">(</span>{{ displayedTime }}<span *ngIf="displayedTime">)</span></span>
        </button>
        <button class="btn btn-outline-light"
                type="button"
                (click)="handleLogoutBtnClicked()"
                *ngIf="!loggedOut"
                id="logOutBtnInModal"
        >
            Log out
        </button>
    </div>
</div>