{
  "name": "degarmo-talent-dashboard",
  "version": "19.0.5",
  "types": "typings.d.ts",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build-prod": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --prod",
    "refreshVSToken": "vsts-npm-auth -config .npmrc"
  },
  "private": true,
  "browser": {
    "crypto": false
  },
  "dependencies": {
    "@angular-slider/ngx-slider": "^19.0.0",
    "@angular/animations": "^19.1.6",
    "@angular/common": "^19.1.6",
    "@angular/compiler": "^19.1.6",
    "@angular/core": "^19.1.6",
    "@angular/forms": "^19.1.6",
    "@angular/localize": "^19.1.6",
    "@angular/platform-browser": "^19.1.6",
    "@angular/platform-browser-dynamic": "^19.1.6",
    "@angular/router": "^19.1.6",
    "@ng-bootstrap/ng-bootstrap": "^18.0.0",
    "@ng-select/ng-select": "^13.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.6",
    "angular-cropperjs": "^14.0.1",
    "angular-froala-wysiwyg": "^4.4.0",
    "bootstrap": "^5.3.2",
    "bs-custom-file-input": "^1.3.4",
    "chart.js": "^4.4.2",
    "cropperjs": "^1.5.12",
    "crypto-browserify": "^3.12.0",
    "crypto-js": "^4.1.1",
    "degarmo-lib": "^19.0.2",
    "echarts": "~5.4.3",
    "froala-editor": "^4.4.0",
    "jquery": "^3.6.1",
    "jquery-ui": "^1.13.2",
    "jspdf": "^2.5.1",
    "keyboardevent-key-polyfill": "^1.1.0",
    "metismenujs": "^1.3.1",
    "mobile-drag-drop": "^3.0.0-rc.0",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "ng-otp-input": "^1.9.3",
    "ng2-search-filter": "^0.5.1",
    "ngx-capture": "^0.14.0",
    "ngx-chips": "^3.0.0",
    "ngx-cookie-service": "^19.1.0",
    "ngx-drag-drop": "^19.0.0",
    "ngx-dropzone-wrapper": "^17.0.0",
    "ngx-echarts": "^19.0.0",
    "ngx-image-cropper": "^9.1.1",
    "ngx-mask": "^19.0.6",
    "ngx-owl-carousel-o": "^19.0.0",
    "ngx-spinner": "^17.0.0",
    "ngx-toastr": "^19.0.0",
    "ngx-ui-switch": "^15.0.0",
    "pace-ts": "^1.0.2",
    "progress-tracker": "^2.0.7",
    "resize-observer-polyfill": "^1.5.1",
    "rxjs": "^7.8.1",
    "sass-loader": "^14.1.1",
    "simplebar-angular": "^3.3.0",
    "sweetalert2": "^11.16.0",
    "tslib": "^2.4.0",
    "zone.js": "~0.15.0",
    "zxcvbn3": "^0.1.1"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.7",
    "@angular/cli": "~19.1.7",
    "@angular/compiler-cli": "^19.1.6",
    "@types/jasmine": "^4.0.3",
    "jasmine-core": "~4.3.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.1",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "ng-packagr": "^19.1.2",
    "typescript": "~5.7.3",
    "vsts-npm-auth": "^0.43.0"
  }
}
