import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthDataModel, SessionInfoService, UserAccountService } from 'degarmo-lib';

@Component({
    selector: 'app-idle-logout-modal',
    templateUrl: './idle-logout-modal.component.html',
    styleUrls: ['./idle-logout-modal.component.scss'],
    standalone: false
})
export class IdleLogoutModalComponent implements OnInit {
  @Input() minutesToLogout: number = 1;
  timer: number = 59; // in seconds
  displayedTime: string = '';
  @Input() description: string = 'Your session will soon expire due to inactivity. Please choose Continue to remain signed in.';
  @Input() loggedOut: boolean = false;
  counterInterval: any = null;

  constructor(
    private activeModal: NgbActiveModal,
    private sessionInfoService: SessionInfoService,
    private userAccountService: UserAccountService
  ) { }

  ngOnInit(): void {
    if (!this.loggedOut) {
      this.timer = (60 * this.minutesToLogout) - 1;
      this.startTimer();
    } else {
      this.timer = 0;
    }
  }

  startTimer = () => {
    let timer = this.timer;
    let minutes: number | string;
    let seconds: number | string;
    
    this.counterInterval = setInterval(() => {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.displayedTime = `${minutes}:${seconds}`;

      if (timer <= 0) {
        clearInterval(this.counterInterval);
        setTimeout(() => { 
          this.loggedOut = true;
          this.description = 'You were signed out due to inactivity.';
          this.activeModal.dismiss({ logout: true, showCloseModal: true })
        });
      } else {
        --timer;
      }
    }, 1000);
  }

  handleContinueBtnClicked = () => {
    clearInterval(this.counterInterval);
    this.activeModal.dismiss({ continue: true });
  }

  handleLogoutBtnClicked = () => {
    clearInterval(this.counterInterval);
    this.activeModal.dismiss({ logout: true });
  }
}
